// eslint-disable-next-line import/no-cycle
import axiosIns from '@axios'

export default {
  namespaced: true,
  state: {
    authenticated: false,
    token: null,
    user: {},
  },
  getters: {
    authenticated(state) {
      return state.authenticated
    },
    user(state) {
      return state.user
    },
  },
  mutations: {
    SET_AUTHENTICATED(state, token) {
      state.authenticated = !!token
      state.token = token ?? null

      // set/unset global bearer token in axios
      if (state.authenticated) {
        axiosIns.defaults.headers.common.Authorization = `Bearer ${state.token}`
        localStorage.setItem('accessToken', state.token)
        localStorage.setItem('refreshToken', state.token)
      } else {
        axiosIns.defaults.headers.common.Authorization = null
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
      }
    },
    SET_USER(state, value) {
      state.user = value
    },
  },
  actions: {
    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/auth/login', payload).then(response => {
          commit('SET_AUTHENTICATED', response.data.payload.token)
          commit('SET_USER', response.data.payload.user)
          resolve(response.data)
        }).catch(error => {
          commit('SET_USER', {})
          commit('SET_AUTHENTICATED', null)
          reject(error)
        })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/auth/logout').then(response => {
          commit('SET_USER', {})
          commit('SET_AUTHENTICATED', null)
          resolve(response.data)
        }).catch(error => {
          commit('SET_USER', {})
          commit('SET_AUTHENTICATED', null)
          reject(error)
        })
      })
    },
    getNotifications({ commit }, params) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/user/notifications', { params }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    markNotificationsRead({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/user/mark-notifications-read', payload).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
