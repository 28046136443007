import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import {nextTick} from "@vue/composition-api";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  // withCredentials: true,
  baseURL: process.env.VUE_APP_BASE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

/* Response Interceptors */
const interceptResErrors = err => {
  try {
    // check for response code 401 and redirect to login
    if (err.response.status === 401) {
      store.dispatch('auth/logout')
      window.location.href = '/login'
    }
    // err = Object.assign(new Error(), { message: err.response.data })
    return Promise.reject(err)
  } catch (e) {
    // check for response code 123 and redirect to login
    // Will return err if something goes wrong
    return Promise.reject(e)
  }
}
const interceptResponse = res => {
  try {
    // check for response code 123 and redirect to login
    /*
    if (res.status === 401) {
      store.dispatch('auth/logout')
      window.location.href = '/login'
      return Promise.reject(Object.assign(new Error(), {message: res.status}))
    }
    */
    return Promise.resolve(res)
  } catch (e) {
    // check for response code 123 and redirect to login
    return Promise.reject(e)
  }
}
axiosIns.interceptors.response.use(interceptResponse, interceptResErrors)

/*
axiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      store.dispatch('auth/logout')
      window.location.href = '/login'

      // show warning and redirect to login
      // app.$swal.fire({
      //   text: i18n.t('error.token_expired_alert_text'),
      //   icon: 'error',
      //   backdrop: '#a1a1a1',
      // }).then(() => {
      //   window.location.href = '/login'
      // })
      return error
    }
    return error
  },
)
*/

/*
  // example usage:
  this.$httpDownload({
    blob: response.data, // response from api
    fileType: 'application/zip', // type e.g application/zip,application/pdf etc.
    fileName: `myFileName.zip`, // any name
  });
*/
const httpGetFile = props => {
  if (!props) return

  const fileURL = window.URL.createObjectURL(new Blob([props.blob], { type: props.fileType }))
  const fileLink = document.createElement('a')
  fileLink.href = fileURL
  fileLink.setAttribute('download', props.fileName)
  document.body.appendChild(fileLink)

  nextTick(() => {
    fileLink.click()
    fileLink.remove()
  })
}

Vue.prototype.$httpGetFile = httpGetFile
Vue.prototype.$http = axiosIns

export { httpGetFile }
export default axiosIns
