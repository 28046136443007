import store from "@/store";

export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import(/* webpackChunkName: "auth" */'@/views/eplan/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/logout',
    name: 'auth-logout',
    component: () => import(/* webpackChunkName: "auth" */'@/views/eplan/Logout.vue'),
    meta: {
      layout: 'full',
      resource: 'app',
      action: 'access',
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "notifications" */'@/views/eplan/notifications/notification-list/NotificationList.vue'),
    meta: {
      resource: 'app',
      action: 'access',
      pageTitle: 'Notifications',
      breadcrumb: [
        {
          text: 'All notifications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/',
    name: 'dashboard-eplan',
    component: () => import(/* webpackChunkName: "dashboard" */'@/views/eplan/dashboard/Dashboard.vue'),
    meta: {
      resource: 'app',
      action: 'access',
    },
  },

  // region [ Document routes ]
  {
    path: '/document/list',
    name: 'document-list',
    component: () => import(/* webpackChunkName: "document" */'@/views/eplan/documents/document-list/DocumentList.vue'),
    meta: {
      resource: 'document',
      action: 'view',
      pageTitle: 'Documents',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/document/add/',
    name: 'document-add',
    component: () => import(/* webpackChunkName: "document" */'@/views/eplan/documents/document-add/DocumentAdd.vue'),
    meta: {
      resource: 'document',
      action: 'add',
      pageTitle: 'Documents',
      breadcrumb: [
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/document/edit/:id',
    name: 'document-edit',
    component: () => import(/* webpackChunkName: "document" */'@/views/eplan/documents/document-edit/DocumentEdit.vue'),
    meta: {
      resource: 'document',
      action: 'edit',
      pageTitle: 'Documents',
      breadcrumb: [
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/document/preview/:hashid',
    name: 'document-preview',
    component: () => import(/* webpackChunkName: "document" */'@/views/eplan/documents/document-preview/DocumentPreview.vue'),
    meta: {
      // layout: 'full',
      resource: 'document',
      action: 'view',
    },
  },
  // endregion  [ Document routes ]

  // region [ Report routes ]
  {
    path: '/report/project-type',
    name: 'report-project-type',
    component: () => import(/* webpackChunkName: "report" */'@/views/eplan/reports/report-project-type/ReportProjectTypeByState.vue'),
    meta: {
      resource: 'report',
      action: 'view',
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Project Types by State',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report/plan-type',
    name: 'report-plan-type',
    component: () => import(/* webpackChunkName: "report" */'@/views/eplan/reports/report-plan-type/ReportPlanTypeByState.vue'),
    meta: {
      resource: 'report',
      action: 'view',
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Plan Types by State',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report/plan-owner',
    name: 'report-plan-owner',
    component: () => import(/* webpackChunkName: "report" */'@/views/eplan/reports/report-plan-owner/ReportPlanOwner.vue'),
    meta: {
      resource: 'report',
      action: 'view',
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Plan Owner by State',
          active: true,
        },
      ],
    },
  },
  // endregion [ Report routes ]

  // User routes
  {
    path: '/user/list',
    name: 'user-list',
    component: () => import(/* webpackChunkName: "user" */'@/views/eplan/users/users-list/UsersList.vue'),
    meta: {
      resource: 'user',
      action: 'manage',
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/view/:hashid',
    name: 'user-view',
    component: () => import(/* webpackChunkName: "user" */'@/views/eplan/users/users-view/UsersView.vue'),
    meta: {
      resource: 'user',
      action: 'manage',
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/edit/:hashid',
    name: 'user-edit',
    component: () => import(/* webpackChunkName: "user" */'@/views/eplan/users/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'user',
      action: 'manage',
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/audit/list',
    name: 'audit-list',
    component: () => import(/* webpackChunkName: "audit" */'@/views/eplan/audits/audit-list/AuditList.vue'),
    meta: {
      resource: 'audit',
      action: 'view',
      pageTitle: 'Audits',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]
