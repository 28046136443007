import mock from '@/@fake-db/mock'

const data = {
  documentTypes: {
    lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
    chartInfo: [
      {
        icon: 'LayersIcon',
        name: 'Drawing/Plan',
        iconColor: 'text-primary',
        usage: 58.6,
        upDown: 2,
      },
      {
        icon: 'MonitorIcon',
        name: 'Office Documents',
        iconColor: 'text-warning',
        usage: 34.9,
        upDown: 8,
      },
      {
        icon: 'ImageIcon',
        name: 'Images',
        iconColor: 'text-danger',
        usage: 6.5,
        upDown: -5,
      },
    ],
  },
}

mock.onGet('/eplan/document-types').reply(() => [200, data.documentTypes])