<template>
  <b-modal
    id="modal-idle"
    v-model="isIdle"
    header-class="p-2 border-bottom-0"
    footer-class="p-2 border-top-0"
    title="Session Expired"
    @show="modalShown"
    @hidden="modalHidden"
  >
    <div class="p-1">
      <p>You have left this browser idle for 30 minutes.</p>
      <p>System will logout in, {{ seconds }} seconds</p>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
  name: 'ModalIdle',
  components: {
    BModal,
  },
  props: {
    isIdle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      time: 30000,
      timerId: null,
    }
  },
  computed: {
    seconds() {
      return this.time / 1000
    },
  },
  destroyed() {
    clearInterval(this.timerId)
  },
  methods: {
    modalShown(e) {
      this.time = 30000

      if (this.$route.name === 'auth-login') {
        e.preventDefault()
        return
      }

      this.timerId = setInterval(() => {
        this.time -= 1000

        if (this.time < 1) {
          clearInterval(this.timerId)
          if (this.$store.state.idleVue.isIdle) {
            this.$router.push('/logout')
          } else {
            clearInterval(this.timerId)
          }
        }
      }, 1000)
    },
    modalHidden() {
      clearInterval(this.timerId)
      this.time = 30000
    },
  },
}
</script>
